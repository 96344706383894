import React from 'react';
import { Form } from 'connex-cds';
import { find } from 'lodash';
import { useCreateOrderPrice } from '../../../../query-hooks/order';
import { useTicketContext } from './TicketContext';
import { useCompanySetup, useProductSetup, useVehicleSetup, useVehicleTypeSetup } from './MasterDataProvider';

export const useSetPricing = () => {
  const { ticket, paymentMethod } = useTicketContext();
  const createPricing = useCreateOrderPrice(ticket?.orderRef);
  const { setFieldValue } = Form.useFormContext();

  const productSetup = useProductSetup();
  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup?.data, { id: ticket?.vehicleId });
    const targetVehicleType = find(vehicleTypeSetup?.data, {
      crn: targetVehicle?.vehicleType?.vehicleTypeRef,
    });
    return targetVehicleType?.id;
  }, [vehicleSetup?.data, vehicleTypeSetup?.data, ticket?.vehicleId]);

  const setPricing = React.useCallback(async () => {
    const products = productSetup?.data
      ?.filter?.(product => product?.isDriverSellable)
      ?.filter?.(product => product?.status === 'ACTIVE')
      ?.filter?.(product =>
        companySetup?.data?.isMulticountry === true
          ? product?.countries?.includes(ticket?.origin?.address?.countryCode) || !product?.countries?.length
          : product
      )
      ?.filter?.(product => product?.vehicleTypes?.includes(vehicleType) || !product?.vehicleTypes?.length);

    const items = products.map(product => {
      const newLineItem = {
        item: {
          productRef: product?.crn,
        },
      };
      return newLineItem;
    });
    const response = await createPricing({ lineItems: items });

    setFieldValue('pricing', response ?? []);
  }, [
    paymentMethod,
    productSetup?.data,
    companySetup?.data?.isMulticountry,
    ticket?.origin?.address?.countryCode,
    vehicleType,
    createPricing,
    setFieldValue,
  ]);

  return { setPricing };
};
